import http from '@/util/http-common'
const baseURL = "https://myweb.net.au/api"

class SettingsDataService {
    getAll() {
        return http.get(baseURL + '/mobileStatus')
    }

    getDevices(data) {
        return http.post(baseURL + '/Device/GetAll', data)
    }

    getAllFtpConfig() {
        return http.get(baseURL + '/Configurations')
    }

    createFtpConfig(data) {
        return http.post(baseURL + '/Configurations', data)
    }

    updateFtpConfig(data) {
        return http.patch(baseURL + `/Configurations/${data.id}`, data)
    }

    deleteFtpConfig(id) {
        return http.delete(baseURL + `/Configurations/${id}`)
    }

    create(data) {
        return http.post(baseURL + '/mobileStatus/Message', data)
    }

    update(data) {
        return http.patch(baseURL + '/mobileStatus/Message', data)
    }

    delete(id) {
        return http.delete(baseURL + `/mobileStatus/Message/${id}`)
    }

    getAppVersions() {
        return http.get(baseURL + '/Device/APKVersions')
    }

    uploadApk(data) {
        return http.post(baseURL + '/Device/UploadAPK', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    updateRequiredVersion(data) {
        return http.post(baseURL + '/Device/UpdateRequiredVersion', data)
    }

    updateRequiredDownload(data) {
        return http.post(baseURL + '/Device/UpdateRequiredDownload', data)
    }

    updateDeviceFtpConfigurations(data) {
        return http.post(baseURL + '/Device/UpdateDeviceFtpConfigurations', data)
    }

    settingsGetAll() {
        return http.get(baseURL + '/Setting')
    }

    addSettings(data) {
        return http.post(baseURL + '/Setting', data)
    }

    updateSettings(data) {
        return http.patch(baseURL + `/Setting/${data.id}`, data)
    }

    deleteSettings(id) {
        return http.delete(baseURL + `/Setting/${id}`)
    }

    getServerUrls() {
        return http.get(baseURL + '/Setting/GetServerUrls')
    }

    updateCurrentServerUrl(data) {
        return http.post(baseURL + '/Device/UpdateCurrentServerUrl', data)
    }

    updateDeviceConfigurations(data) {
        return http.post(baseURL + '/Device/BulkUpdateDeviceConfigurations', data)
    }

    getDbConnectionStrings() {
        return http.get(baseURL + '/Setting/GetDbConnectionStrings')
    }

    migrateData(data) {
        return http.post(baseURL + '/Setting/MigrateData', data)
    }

    deviceLogs(data) {
        return http.post(baseURL + '/DeviceLogs/GetAll', data)
    }
}

export default new SettingsDataService()
